import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

const HelpPage = () => {
  const data = useStaticQuery(HELP_CONTENT)
  const content = data?.wordpressAcfOptions?.options?.help_page_group?.content

  return (
    <Layout
      seo={{ title: "Help" }}
      mainHeader={"Help"}
      mainContent={
        <Content>
          <Title>Help</Title>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Content>
      }
    />
  )
}
export default HelpPage

const Title = styled.h2`
  font-family: "Poppins", sans-serif;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  padding: 0;
  margin: 40px;
  line-height: 1.2;
`
const Content = styled.div`
  padding: 30px 40px;
  @media (max-width: 768px) {
    padding: 20px;
  }
`

const HELP_CONTENT = graphql`
  {
    wordpressAcfOptions {
      options {
        help_page_group {
          content
        }
      }
    }
  }
`
